import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import constants from '../../core/constants';
import Formatter from '../../core/Formatter';
import { getCoinTransactionsTotals } from '../../core/app/selectors';
import { ROUTE_COIN_NEW, ROUTE_PF_DELETE, ROUTE_PF_EDIT } from '../../core/routes';
import CoinOverview from '../Coin/Overview';
import ProfitChart from '../Charts/ProfitChart';
import BTCChart from '../Charts/BTCChart';

function Portfolio(props) {

  const { id } = props;
  const pf = props.portfolio[id];
  
  let totalInvested = 0;
  let totalWithdrawn = 0;
  let totalCurrentVal = 0;
  let fundsByValue = [];
  let activeCoins = [];
  let soldCoins = [];

  // if the raw data from api are already loaded (in state)
  if (props.currentPrices.RAW && pf.hasOwnProperty('coins')) {
    for (const [coinId, fund] of Object.entries(pf.coins)) {
      const { pieces, invested, withdrawn } = props.getCoinTransactionsTotals(id, coinId);
      totalInvested += invested;
      totalWithdrawn += withdrawn;
      // if price API does not know the token yet
      let val = invested - withdrawn;
      if (props.currentPrices.RAW.hasOwnProperty(fund.symbol)) {
        val = pieces * props.currentPrices.RAW[fund.symbol][pf.metadata.currency].PRICE;
      }
      totalCurrentVal += val;
      let extracols = null;
      if (fund.hasOwnProperty('unlock_date')) {
        const dtl = Math.ceil((new Date(fund.unlock_date).getTime() - new Date().getTime())/1000/60/60/24);
        extracols = [
          <tr key="dtu"><td>Days till unlocked</td><td>{ Formatter.formatNegative(Math.max(dtl, 0)) }</td></tr>,
          <tr key="mtr"><td>Days till release ends</td><td>{ Formatter.formatNegative(dtl + Math.ceil(30.5 * fund.release_months)) }</td></tr>,
        ];
      }
      if (pieces > 0) {
        fundsByValue.push([
          <CoinOverview key={ coinId } pfId={ id } coinId={ coinId } extracols={ extracols } />,
          val
        ]);
      } else {
        soldCoins.push(<CoinOverview key={ coinId } pfId={ id } coinId={ coinId } />);
      }
    }
    // sort by highest value
    fundsByValue.sort(function(a, b) {
      return b[1] - a[1];
    });
    for (const f of fundsByValue) {
      activeCoins.push(f[0]);
    }
    
  } else {
    activeCoins.push(<div key="placeholder" className="loading">Loading portfolio &hellip;</div>);
  }

  return (
    <section className="portfolio-section">
      
      <div className="row my-4">
        <div className="col-auto">
          <h2>{ pf.metadata.name }</h2>
        </div>
        <div className="col-auto ms-auto align-items-center d-flex">
          <Link to={ ROUTE_COIN_NEW(id) } className="btn btn-outline-primary ms-3">
            <span role="img" aria-label="add">➕</span> New Coin
          </Link>
          <Link to={ ROUTE_PF_EDIT(id) } className="btn btn-outline-primary ms-3">
            <span role="img" aria-label="edit">✏️</span> Edit
          </Link>
          <Link to={ ROUTE_PF_DELETE(id) } className="btn btn-outline-primary btn-icon ms-3">
            <span role="img" aria-label="delete">🗑️</span>
          </Link>
        </div>
      </div>

      <div className={ "totals mb-4" + (props.loading ? ' shake' : '') }>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <span>Total invested:</span>
            <div>
              { Formatter.format(totalInvested, pf.metadata.currency, constants.DECIMALS_PRICE, props.euDecimals) }
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <span>Total realized profit:</span>
            <div>
              { Formatter.format(totalWithdrawn, pf.metadata.currency, constants.DECIMALS_PRICE, props.euDecimals) }
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <span>Current total crypto value:</span>
            <div>
              { Formatter.format(totalCurrentVal, pf.metadata.currency, constants.DECIMALS_PRICE, props.euDecimals) }
            </div>  
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <span>Total value incl. realized profit:</span>
            <div>
              { Formatter.format(totalCurrentVal + totalWithdrawn, pf.metadata.currency, constants.DECIMALS_PRICE, props.euDecimals) }
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <span>Profit:</span>
            <div>
              { Formatter.formatColor(totalCurrentVal + totalWithdrawn - totalInvested, pf.metadata.currency, constants.DECIMALS_PRICE, props.euDecimals) }
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <span>Profit pct:</span>
            <div>
              { Formatter.formatPct(100 * ((totalCurrentVal + totalWithdrawn) / totalInvested - 1), 2, props.euDecimals) }
            </div>
          </div>
        </div>
      </div>

      { activeCoins.length > 0 &&
        <div className="row gx-4 gy-4">
          { activeCoins }
        </div>
      }

      { soldCoins.length > 0 && <h3 className="my-4"><span role="img" aria-label="death">☠️</span> Coins with no holdings</h3> }
      { soldCoins.length > 0 && 
        <div className="row gx-4 gy-4">
          { soldCoins }
        </div> 
      }

      <div className="row">
        <div className="col-sm-12">
          <ProfitChart pfId={ id } />
          <BTCChart pfId={ id } />
        </div>
      </div>

    </section>
  );
}

function mapStateToProps(state) {
  return {
    currentPrices: state.app.currentPrices,
    loading: state.app.loading,
    portfolio: state.app.portfolio,
    euDecimals: state.app.settings.euDecimals,
    getCoinTransactionsTotals: (pfId, coinId, atTimestamp) => getCoinTransactionsTotals(pfId, coinId, atTimestamp)(state),
  }
}

export default connect(mapStateToProps)(Portfolio);
