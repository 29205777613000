import React from 'react';
import constants from './constants';

class Formatter {

  static precisionRound(number, precision, euDecimals) {
    precision = precision ? precision : constants.DECIMALS_PRICE;
    // more decimals for small prices
    if (number > 0) {
      if (number < 1) {
        precision = Math.max(precision, 4);
        if (number < 0.01) {
          if (number < 0.0001) {
            precision = Math.max(precision, 8);
          }
        }
      }
    }
    const factor = Math.pow(10, precision);
    const num = Number((Math.round(number * factor) / factor).toFixed(precision));
    let formatted = isNaN(num) ? "" : num.toString();
    return euDecimals === false ? formatted : formatted.replace(".", ",");
  }

  static format(value, units, precision, euDecimals) {
    units = typeof units !== 'undefined' ? units : constants.DEFAULT_CURRENCY;
    return Formatter.precisionRound(value, precision, euDecimals) + (units ? ' ' + units : '');
  }
  static formatColor(value, units, precision, euDecimals) {
    const positive = value >= 0;
    return <span className={ positive ? 'green' : 'red' }>{ (positive ? '+' : '') + Formatter.format(value, units, precision, euDecimals) }</span>;
  }
  static formatNegative(value) {
    const negative = value > 0;
    return <span className={ negative ? 'red' : 'green' }>{ value }</span>;
  }
  static formatPct(value, precision, euDecimals) {
    precision = precision ? precision : constants.DECIMALS_PERCENT;
    return Formatter.formatColor(value, '%', precision, euDecimals);
  }

  static parseNum(value, euDecimals) {
    euDecimals = euDecimals !== false;
    value = value.toString();
    if (value.length) {
      const remove = euDecimals ? '.' : ',';
      value = value.replaceAll(remove, '');
      if (euDecimals) {
        value = value.replace(',', '.');
      }
      value = parseFloat(value);
      return isNaN(value) ? 0.0 : value;
    }
    return 0.0;
  }
}

export default Formatter;
