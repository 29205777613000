import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { FIREBASE_DB_URL } from '../../core/constants';
import { checkLoggedUser, fetchUserData, createNewPortfolio } from '../../core/app/thunks';
import { ROUTE_LOGIN, ROUTE_ROOT } from '../../core/routes'
import { pfExists } from '../../core/app/selectors';
import { buildFetchConfig } from '../../core/helpers';

function PortfolioForm(props) {

  const baseState = {
    name: '',
    currency: 'EUR',
  };
  const [ state, setState ] = useState(baseState);
  const { id } = useParams();
  const navigate = useNavigate();

  // Similar to componentDidMount
  useEffect(() => {
    props.checkLoggedUser();
    // load user's portfolio data in case of browser reload - not needed if this is new portfolio form (no id in route)
    if (id) {
      props.fetchUserData(false)
        .then(() => {
          // user data loaded
        });
    }
  }, []);

  // will run when value of "pfExists" changes
  useEffect(() => {
    // load all form fields from store in case nothing has been entered yet
    if (props.pfExists(id) && !state.name) {
      setState(prevState => {
        return {
          ...prevState,
          name: props.portfolio[id].metadata.name,
          currency: props.portfolio[id].metadata.currency,
        };
      });
    }
  }, [props.pfExists(id)]);

  const portfolioRequest = (event) => {
    event.preventDefault();
    if (id) {
      // existing - update
      const url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/portfolio/" 
        + id + "/metadata.json?auth=" + props.user.authToken;
      const data = {
        name: state.name,
        currency: state.currency,
      };
      const config = buildFetchConfig('PUT', data);
      fetch(url, config)
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.hasOwnProperty('name')) {
            reloadDataAndRedirect(id);
          }
        });
    } else {
      // new - create
      const data = {
        metadata: {
          name: state.name,
          currency: state.currency,
        }
      };
      props.createNewPortfolio(data)
        .then((newId) => {
          reloadDataAndRedirect(newId);
        });
    }
  }

  const reloadDataAndRedirect = (tabId) => {
    tabId = tabId || id;
    props.fetchUserData(true)
      .then(() => {
        navigate(ROUTE_ROOT, { state: {selectedTab: tabId} });
      });
  }

  const handleInputChange = (event) => {
    const fieldName = event.target.id;
    const fieldVal = event.target.value;

    setState(prevState => {
      return {
        ...prevState,
        [fieldName]: fieldVal
      };
    });
  }

  if (null === JSON.parse(localStorage.getItem("user"))) {
    return <Navigate to={ ROUTE_LOGIN }/>;
  }
  
  const header = (id ? 'Edit' : 'Create') + ' Portfolio';

  // update browser title
  document.title = header;

  return (
    <main>
      <h2 className="text-center my-4">{ header }</h2>
      <div className="row justify-content-center my-4">
        <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
          <form onSubmit={ portfolioRequest }>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Portfolio name</label>
              <input type="text" className="form-control" id="name" value={ state.name } onChange={ handleInputChange } required />
            </div>
            <div className="mb-3">
              <label htmlFor="currency" className="form-label">Base currency</label>
              <Form.Select aria-label="Currency" id="currency" value={ state.currency } onChange={ handleInputChange }>
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
              </Form.Select>
            </div>
            <div className="row justify-content-center">
              <div className="col-4 d-grid">
                <button type="submit" className="btn btn-outline-primary">
                  { id ? 'Update' : 'Create' }
                </button>
              </div>
              <div className="col-4 d-grid">
                <Link to={ ROUTE_ROOT } state={ {selectedTab: id} } className="btn btn-outline-secondary">
                  Back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );

}

function mapStateToProps(state) {
  return {
    user: state.app.user,
    portfolio: state.app.portfolio,
    pfExists: (id) => pfExists(id)(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // thunk actions
    checkLoggedUser: () => dispatch(checkLoggedUser),
    fetchUserData: (forceReload) => dispatch(fetchUserData(forceReload)),
    createNewPortfolio: (data) => dispatch(createNewPortfolio(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioForm);
