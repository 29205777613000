import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCoinTransactionsTotals } from '../../core/app/selectors';
// TODO: extend my Formatter from https://www.npmjs.com/package/react-number-format
//import NumberFormat from 'react-number-format';
import Formatter from "../../core/Formatter"
import constants from '../../core/constants';
import { ROUTE_COIN } from '../../core/routes';

function Overview(props) {

  const navigate = useNavigate();

  const handleclick = (event) => {
    navigate(ROUTE_COIN(props.pfId, props.coinId));
  }

  const renderTable = (props) => {
    const coin = props.portfolio[props.pfId].coins[props.coinId];
    const pfCurrency = props.portfolio[props.pfId].metadata.currency;
    const data = props.data.RAW[coin.symbol];
    const extracols = props.extracols;
    const { pieces, invested, withdrawn } = props.getCoinTransactionsTotals(props.pfId, props.coinId);
    const value_current = data ? pieces * data[pfCurrency].PRICE : invested - withdrawn;
    const firstDate = coin.transactions && Object.keys(coin.transactions).length 
      ? coin.transactions[Object.keys(coin.transactions)[0]].date 
      : '-';
    return (
      <div>
        <table className={ 'coindata' + (props.loading ? ' shake' : '') }>
          <tbody>
            <tr key="price_usd">
              <td>
                Price
              </td>
              <td>
                { Formatter.format(data ? data.USD.PRICE : '-', 'USD', constants.DECIMALS_PRICE, props.euDecimals) }
              </td>
            </tr>
            <tr key="price">
              <td>
                Price
              </td>
              <td>
                { Formatter.format(data ? data.EUR.PRICE : '-', 'EUR', constants.DECIMALS_PRICE, props.euDecimals) }
              </td>
            </tr>
            <tr key="change_24">
              <td>
                Change 24H
              </td>
              <td>
                { Formatter.formatPct(data ? data[pfCurrency].CHANGEPCT24HOUR : '-', constants.DECIMALS_PERCENT, props.euDecimals) }
              </td>
            </tr>
            { pieces > 0 && <tr key="pieces">
              <td>
                Pieces
              </td>
              <td>
                { Formatter.format(pieces, '', constants.DECIMALS_PIECES, props.euDecimals) }
              </td>
            </tr> }
            { pieces > 0 && <tr key="date_first">
              <td>
                Date first bought
              </td>
              <td>
                { firstDate }
              </td>
            </tr> }
            <tr key="cach_in">
              <td>
                Cash invested
              </td>
              <td>
                { Formatter.format(invested, pfCurrency, constants.DECIMALS_PRICE, props.euDecimals) }
              </td>
            </tr>
            <tr key="cash_out">
              <td>
                Realized profit
              </td>
              <td>
                { Formatter.format(withdrawn, pfCurrency, constants.DECIMALS_PRICE, props.euDecimals) }
              </td>
            </tr>
            { pieces > 0 && <tr key="price_current">
              <td>
                Current value
              </td>
              <td>
                { Formatter.format(value_current, pfCurrency, constants.DECIMALS_PRICE, props.euDecimals) }
              </td>
            </tr> }
            <tr key="profit">
              <td>
                Profit
              </td>
              <td>
                { Formatter.formatColor(value_current + withdrawn - invested, pfCurrency, constants.DECIMALS_PRICE, props.euDecimals) }
              </td>
            </tr>
            <tr key="profit_pct">
              <td>
                Profit pct
              </td>
              <td>
                { Formatter.formatPct(100 * ((value_current + withdrawn) / invested - 1), 2, props.euDecimals) }
              </td>
            </tr>
            { extracols }
          </tbody>
        </table>
      </div>
    );
  }

  const coin = props.portfolio[props.pfId].coins[props.coinId];
  const table = props.data.RAW ? renderTable(props) : (
    <div>
      Loading...
    </div>
  );
  const logo = props.data.RAW.hasOwnProperty(coin.symbol)
    ? <img className="coin-logo" src={ "https://www.cryptocompare.com" + props.data.RAW[coin.symbol][constants.DEFAULT_CURRENCY].IMAGEURL }
    alt={coin.symbol + " logo"} />
    : null;
  return (
    <div className="col-sm-12 col-md-6 col-lg-3">
      <div className="coin" onClick={ handleclick }>
        <div className="row">
          <div className="col-auto">
            <h3>{ logo }{ coin.name } [{ coin.symbol }]</h3>
          </div>
          <div className="col-auto ms-auto align-items-center d-flex">

          </div>
        </div>
        { table }
      </div>
    </div>
  );

}

function mapStateToProps(state) {
  return {
    data: state.app.currentPrices,
    loading: state.app.loading,
    portfolio: state.app.portfolio,
    euDecimals: state.app.settings.euDecimals,
    getCoinTransactionsTotals: (pfId, coinId, atTimestamp) => getCoinTransactionsTotals(pfId, coinId, atTimestamp)(state),
  }
}

export default connect(mapStateToProps)(Overview);
