import * as types from './action-types';
import { DEFAULT_EU_DECIMALS } from '../constants';

const defaultState = {
  loading: false,
  user: {},
  settings: {
    euDecimals: DEFAULT_EU_DECIMALS
  },
  // TODO: make portfolio empty and load dynamically
  portfolio: {},
  currentPrices: {},
  histories: {
    BTC: null,
    EUR: null,
    USD: null,
  },
}

export const appReducer = (state = defaultState, {type, payload}) => {
  switch (type) {

    case types.LOADING_START:
      return {
        ...state,
        loading: true
      }

    case types.LOADING_END:
      return {
        ...state,
        loading: false
      }

    case types.UPDATE_CURRENT_PRICES:
      return {
        ...state,
        currentPrices: payload
      }

    case types.UPDATE_HISTORIES:
      const { currency, prices } = payload;
      return {
        ...state,
        histories: {
          ...state.histories,
          [currency]: prices,
        }
    }

    case types.USER_INIT:
      if (payload.hasOwnProperty('portfolio')) {
        state = {
          ...state,
          portfolio: payload.portfolio
        };
      }
      if (payload.hasOwnProperty('settings')) {
        state = {
          ...state,
          settings: {
            ...state.settings,
            ...payload.settings
          }
        };
      }
      return state

    case types.USER_LOGIN:
      localStorage.setItem("user", JSON.stringify(payload));
      return {
        ...state,
        user: payload
      }

    case types.USER_LOAD:
      // load data from local storage only first time / if missing in state (page reload)
      // load firebase auth user data
      const user = JSON.parse(localStorage.getItem("user")) || {};
      if (Object.keys(state.user).length === 0) {
        state = {
          ...state,
          user
        };
      }
      // load initial user settings
      const darkSelected = localStorage.getItem("dark");
      const dark = null === darkSelected 
        // if nothing selected, decide by OS
        ? window.matchMedia("(prefers-color-scheme: dark)").matches
        // otherwise respect selection
        : JSON.parse(darkSelected) === true;
      if (!state.settings.hasOwnProperty('dark')) {
        toggleDarkTheme(dark);
        state = {
          ...state,
          settings: {
            ...state.settings,
            dark
          }
        };
      }
      return state;

    case types.USER_LOGOUT:
      localStorage.removeItem("user");
      return {
        ...state,
        user: {},
        portfolio: {},
        currentPrices: {}
      }

    case types.USER_SETTINGS_CHANGE:
      if (payload.hasOwnProperty("dark")) {
        localStorage.setItem("dark", payload.dark);
        toggleDarkTheme(payload.dark);
      }
      return {
        ...state,
        settings: payload
      }

    default:
      return state;

  }
}

const toggleDarkTheme = (dark) => {
  document.head.querySelector('[name=color-scheme]').setAttribute("content", dark ? "dark" : "light");
  document.body.classList.remove(dark ? "light-theme" : "dark-theme");
  document.body.classList.add(dark ? "dark-theme" : "light-theme");
};
