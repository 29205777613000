export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';

export const UPDATE_CURRENT_PRICES = 'UPDATE_CURRENT_PRICES';
export const UPDATE_HISTORIES = 'UPDATE_HISTORIES';

export const USER_INIT = 'USER_INIT';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOAD = 'USER_LOAD';

export const USER_SETTINGS_CHANGE = 'USER_SETTINGS_CHANGE';
