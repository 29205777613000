import React from 'react';
import { connect } from 'react-redux';
import { fetchCurrentPrices } from '../../core/app/thunks';

function RefreshButton(props) {
  const handleclick = (event) => {
    props.fetchCurrentPrices();
  }

  return (
    <button className="btn btn-outline-primary refresh-button hidden-xs hidden-sm" onClick={ handleclick }>
      <span>reload</span>
    </button>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentPrices: () => dispatch(fetchCurrentPrices)
  }
}

export default connect(null, mapDispatchToProps)(RefreshButton);
