import { loadingStart, loadingEnd, updateCurrentPrices, loadUser, logoutUser, initUser, changeUserSettings } from "./actions";
import { getAllSymbols, portfolioLoaded, userIsLogged } from "./selectors";
import constants, { FIREBASE_DB_URL } from '../constants';

export function fetchUserData(forceReload) {
    forceReload = forceReload === true;
    return async function fetchUserDataThunk(dispatch, getState) {

        const state = getState();

        if (userIsLogged(state) && (forceReload || !portfolioLoaded(state))) {
            const url = FIREBASE_DB_URL + "/users/" + state.app.user.userId + ".json?auth=" + state.app.user.authToken;
            const config = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await fetch(url, config);
            const userData = await response.json();
        
            if (userData.hasOwnProperty('portfolio')) {
                // dispatch redux action to save the portfolio data & user settings into store
                dispatch(initUser(userData));
        
                // get current price data for all coins
                dispatch(fetchCurrentPrices);
        
                // get history data for each coin (for chart)
                //this.fetchHistories();
        
                // get history data for each coin (for chart)
                //this.fetchBtcHistories();
            }
        }
    
    }    
}

export function createNewPortfolio(data) {
    return async function createNewPortfolioThunk(dispatch, getState) {

        const state = getState();
    
        const url = FIREBASE_DB_URL + "/users/" + state.app.user.userId + "/portfolio.json?auth=" + state.app.user.authToken;
        data = data || {
          metadata: {
            name: 'Default portfolio',
            currency: constants.DEFAULT_CURRENCY,  
          },
        };
        const config = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        };
        const response = await fetch(url, config);
        const responseData = await response.json();
    
        if (responseData.hasOwnProperty('name')) {
            console.log(responseData.name + ' created');
            return responseData.name;
        }
    } 
}

export function saveUserSettings(data) {
    return async function saveUserSettingsThunk(dispatch, getState) {

        // update in state & store also in LS
        dispatch(changeUserSettings(data));

        const state = getState();
    
        const url = FIREBASE_DB_URL + "/users/" + state.app.user.userId + "/settings.json?auth=" + state.app.user.authToken;
        const config = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        };
        const response = await fetch(url, config);
        const responseData = await response.json();
    
        console.log(responseData);
    } 
}

export async function fetchCurrentPrices(dispatch, getState) {

    dispatch(loadingStart());

    const state = getState();
    const fsyms = getAllSymbols(state).join();
    if (fsyms.length) {
        const tCurrency = [constants.CONVERSION_CURRENCY, constants.DEFAULT_CURRENCY].join();
        const url = 'https://min-api.cryptocompare.com/data/pricemultifull?fsyms=' + fsyms+ '&tsyms=' + tCurrency;
    
        const response = await fetch(url);
        const currentPrices = await response.json();
    
        dispatch(updateCurrentPrices(currentPrices));
    }

    dispatch(loadingEnd());
}

export async function checkLoggedUser(dispatch, getState) {

    // load user from LS in case of page reload
    dispatch(loadUser());

    const state = getState();
    // start logout timer if user is logged
    if (state.app.user.hasOwnProperty('expiresAt')) {
        const msUntilLogout = state.app.user.expiresAt - new Date().getTime();
        console.log('logout in ' + (msUntilLogout / 1000) + ' s');
        const timer = await new Promise(resolve => setTimeout(resolve, msUntilLogout));
    
        // logout once timer is finished
        dispatch(logoutUser());
    }
}
