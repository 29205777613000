import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { ROUTE_PF_NEW } from '../../core/routes';
import Portfolio from './Portfolio';

function PortfolioTabs(props) {

  const [ selectedTab, selectTab ] = useState('');
  const [ selectedByRoute, selectByRoute ] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { portfolio } = props;

  const tabSelect = (k) => {
    if (k === "new_pf") {
      navigate(ROUTE_PF_NEW);
    } else {
      selectTab(k);
    }
  }

  // open tab selected through route location's state object
  if (location.state && location.state.hasOwnProperty('selectedTab') && location.state.selectedTab
    && location.state.selectedTab !== selectedByRoute && selectedByRoute !== selectedTab) {
    selectByRoute(location.state.selectedTab);
    selectTab(location.state.selectedTab);
  }

  // render standalone sections for each portfolio
  let pf_tabs = [];
  for (const pf in portfolio) {
    // open first tab if none was selected
    if (!selectedTab && !pf_tabs.length) {
      selectTab(pf);
    }
    pf_tabs.push(
      <Tab key={ pf } eventKey={ pf } title={ portfolio[pf].metadata.name }>
        <Portfolio id={ pf } />
      </Tab>
    );
  }

  return (
    <div id="portfolio-tabs">
      <Tabs activeKey={ selectedTab } onSelect={ tabSelect }>
        { pf_tabs }
        <Tab key="new_pf" eventKey="new_pf" title="➕" />
      </Tabs>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    portfolio: state.app.portfolio,
  }
}

export default connect(mapStateToProps)(PortfolioTabs);
