export const buildFetchConfig = (method, data) => {
    method = method || 'GET';
    const config = {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
    };
    if (typeof data === 'object' && Object.keys(data).length) {
        config.body = JSON.stringify(data);
    }
    return config;
};

export const diffDays = (date) => {
    const date1 = new Date(date);
    const date2 = new Date();
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
}

export const getTransactionsTotals = (transactions, atTimestamp) => {
    let pieces = 0;
    let invested = 0;
    let withdrawn = 0;
    atTimestamp = atTimestamp || new Date().getTime();
    for (const transaction of Object.values(transactions)) {
        const t = new Date(transaction.date).getTime();
        const fee = transaction.hasOwnProperty('fee') ? transaction.fee : 0;
        // if the requested date is newer than this transaction, count it into the result
        if (t <= atTimestamp) {
            if (transaction.type === "buy") {
                pieces += transaction.pieces;
                invested += transaction.value + fee;
            } else {
                pieces -= transaction.pieces;
                withdrawn += transaction.value - fee;
            }
        }
    }
    return {
        pieces,
        invested,
        withdrawn
    }
}
