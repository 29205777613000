import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header.js';

function Layout(props) {
  return (
    <div className="container-fluid gx-3">
      <Header />
      <Outlet />
    </div>
  );
}

export default Layout;
