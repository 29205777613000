export const SEGMENT_EDIT =                         'edit';
export const SEGMENT_ADD =                          'add';
export const SEGMENT_DELETE =                       'delete';
export const SEGMENT_TX_ID = (txId) =>              txId ? txId : ':txId';

export const ROUTE_ROOT =                           '/';
export const ROUTE_LOGIN =                          '/login';
export const ROUTE_REGISTRATION =                   '/registration';
export const ROUTE_PF_NEW =                         '/new-portfolio';
export const ROUTE_PF = (id) =>                     '/pf/' + (id ? id : ':id');
export const ROUTE_PF_EDIT = (id) =>                 ROUTE_PF(id) + '/' + SEGMENT_EDIT;
export const ROUTE_PF_DELETE = (id) =>               ROUTE_PF(id) + '/' + SEGMENT_DELETE;
export const ROUTE_COIN_NEW = (id) =>                ROUTE_PF(id) + '/' + SEGMENT_ADD;
export const ROUTE_COIN = (id, coinId) =>            ROUTE_PF(id) + '/' + (coinId ? coinId : ':coinId');
export const ROUTE_COIN_EDIT = (id, coinId) =>       ROUTE_COIN(id, coinId) + '/' + SEGMENT_EDIT;
export const ROUTE_COIN_DELETE = (id, coinId) =>     ROUTE_COIN(id, coinId) + '/' + SEGMENT_DELETE;
export const ROUTE_TX_NEW = (id, coinId) =>          ROUTE_COIN(id, coinId) + '/' + SEGMENT_ADD;
export const ROUTE_TX_EDIT = (id, coinId, txId) =>   ROUTE_COIN(id, coinId) + '/' + SEGMENT_TX_ID(txId);
export const ROUTE_TX_DELETE = (id, coinId, txId) => ROUTE_TX_EDIT(id, coinId, txId) + '/' + SEGMENT_DELETE;
