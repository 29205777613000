import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion'
import { fetchUserData } from '../../core/app/thunks';
import { coinExists, portfolioLoaded } from '../../core/app/selectors';
import { ROUTE_COIN, ROUTE_ROOT } from '../../core/routes';
import { FIREBASE_DB_URL } from '../../core/constants';
import { buildFetchConfig } from '../../core/helpers';

function CoinForm(props) {

  const [ name, setName ] = useState('');
  const [ symbol, setSymbol ] = useState('');
  const [ unlock_date, setUnlockDate ] = useState('');
  const [ release_months, setReleaseMonths ] = useState(0);
  const { id, coinId } = useParams();
  const navigate = useNavigate();

  // will run when value of "coinExists" changes
  useEffect(() => {
    // load coin name from store in case nothing has been entered yet
    if (props.coinExists(id, coinId)) {
      if (name === '') {
        setName(props.portfolio[id].coins[coinId].name);
      }
      if (symbol === '') {
        setSymbol(props.portfolio[id].coins[coinId].symbol);
      }
      if (unlock_date === '' && props.portfolio[id].coins[coinId].hasOwnProperty('unlock_date')) {
        setUnlockDate(props.portfolio[id].coins[coinId].unlock_date);
      }
      if (release_months === 0 && props.portfolio[id].coins[coinId].hasOwnProperty('release_months')) {
        setReleaseMonths(props.portfolio[id].coins[coinId].release_months);
      }
    }
  }, [props.coinExists(id, coinId)]);

  const coinRequest = (event) => {
    event.preventDefault();
    const data = {
      name,
      symbol,
      // following properties will not be stored, unless detected later
      unlock_date: null,
      release_months: null,
    };
    // add this for vested coins, only if filled
      if (unlock_date) {
      data.unlock_date = unlock_date;
      data.release_months = release_months;
    }
    if (coinId) {
      // existing - update
      const url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/portfolio/" 
        + id + "/coins/" + coinId + ".json?auth=" + props.user.authToken;
      const config = buildFetchConfig('PATCH', data);
      fetch(url, config)
        .then((response) => response.json())
        .then((responseData) => {
          console.log(responseData);
          props.fetchUserData(true);
          navigate(ROUTE_COIN(id, coinId));
        });
    } else {
      // new - create
      const url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/portfolio/" 
        + id + "/coins.json?auth=" + props.user.authToken;
      const config = buildFetchConfig('POST', data);
      fetch(url, config)
        .then((response) => response.json())
        .then((responseData) => {
          console.log(responseData);
          props.fetchUserData(true);
          if (responseData.hasOwnProperty('name')) {
            navigate(ROUTE_COIN(id, responseData.name));
          }
        });
    }
  }

  const handleInputChange = (event) => {
    switch(event.target.id) {
      case 'name':
        setName(event.target.value);
        break;
      case 'symbol':
        setSymbol(event.target.value);
        break;
      case 'unlock_date':
        setUnlockDate(event.target.value);
        // if unlock date is removed, there is no reason to have release months
        if(event.target.value === '') {
          setReleaseMonths(0);
        }
        break;
      case 'release_months':
        setReleaseMonths(event.target.value);
        break;
    }
  }

  const back = coinId ? ROUTE_COIN(id, coinId) : ROUTE_ROOT;
  const button = coinId ? 'Update' : 'Create';
  const header = coinId ? 'Coin properties' : 'New coin in portfolio ' + props.portfolio[id].metadata.name;

  // update browser title
  document.title = header;

  return (
    <section className="coin-edit">
      <h2 className="text-center my-4">{ header }</h2>
      <div className="row justify-content-center my-4">
        <div className="col-md-12 col-lg-10 col-xl-8 col-xxl-6">
          <form onSubmit={ coinRequest }>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="symbol" className="form-label">Symbol</label>
                <input type="text" className="form-control" id="symbol" aria-describedby="symbolHelp" value={ symbol } onChange={ handleInputChange } required />
                <div id="symbolHelp" className="form-text">Official Symbol / Ticker of the coin. Usually 3 or more capital letters</div>
              </div>
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">Coin name</label>
                <input type="text" className="form-control" id="name" aria-describedby="nameHelp" value={ name } onChange={ handleInputChange } required />
                <div id="nameHelp" className="form-text">Your custom name of the coin. Don't need to the same as official coin name.</div>
              </div>
            </div>

            <Accordion defaultActiveKey="" className="mb-4">
              <Accordion.Item eventKey="0">
                <Accordion.Header as="h3">Optional properties of vested / unreleased / locked coins</Accordion.Header>
                <Accordion.Body>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="unlock_date" className="form-label">Unlock date / TGE</label>
                      <input type="date" className="form-control" id="unlock_date" aria-describedby="unlock_dateHelp"
                        value={ unlock_date } onChange={ handleInputChange } />
                      <div id="unlock_dateHelp" className="form-text">First date, since when your putchased alocation of the token or it's part becomes available to you. Usually close to Token Generation Event (TGE).</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="release_months" className="form-label">Release months</label>
                      <input type="number" className="form-control" id="release_months" aria-describedby="release_monthsHelp" min="0" list="suggestedMonths"
                        value={ release_months } onChange={ handleInputChange } />
                      <datalist id="suggestedMonths">
                        <option value="3"/>
                        <option value="6"/>
                        <option value="9"/>
                        <option value="12"/>
                        <option value="18"/>
                        <option value="24"/>
                        <option value="36"/>
                        <option value="72"/>
                      </datalist>
                      <div id="release_monthsHelp" className="form-text">How many months since the unlock date will it take until the full allocation of the token is available to you for trading.</div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <div className="row justify-content-center">
              <div className="col-4 d-grid">
                <button type="submit" className="btn btn-outline-primary">
                  { button }
                </button>
              </div>
              <div className="col-4 d-grid">
                <Link to={ back } state={ {selectedTab: id} } className="btn btn-outline-secondary">
                  Back
                </Link>
              </div>
            </div>

          </form>
        </div>
      </div>
    </section>
  );
}

function mapStateToProps(state) {
  return {
    user: state.app.user,
    portfolio: state.app.portfolio,
    portfolioLoaded: portfolioLoaded(state),
    coinExists: (id, coinId) => coinExists(id, coinId)(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // thunk actions
    fetchUserData: (forceReload) => dispatch(fetchUserData(forceReload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoinForm);
