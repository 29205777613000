import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { appActions } from '../../core/app';
import { ROUTE_ROOT, ROUTE_REGISTRATION } from '../../core/routes'
import { buildFetchConfig } from '../../core/helpers';

function Login(props) {

  const baseState = {
    email: '',
    password: '',
    error: ''
  };
  const [ state, setState ] = useState(baseState);
  const navigate = useNavigate();

  const loginRequest = (event) => {
    event.preventDefault();
    // reset error
    const error = '';
    setState(prevState => {
      return {
        ...prevState,
        error
      };
    });
    // API request
    const FIREBASE_AUTH_URL = "https://identitytoolkit.googleapis.com/v1/accounts";
    const FIREBASE_API_KEY = "AIzaSyC11of8XwtXnG41_-TUJO8UAEECTSDOmh4";
    const url = FIREBASE_AUTH_URL + ":signInWithPassword?key=" + FIREBASE_API_KEY;
    const data = {
      email: state.email,
      password: state.password,
      returnSecureToken: true
    };
    const config = buildFetchConfig('POST', data);

    fetch(url, config)
      .then((responseText) => responseText.json())
      .then((response) => {
        console.log(response);
        if (response.hasOwnProperty('error') && response.error.hasOwnProperty('message')) {
          console.log(response.error.message);
          setState(prevState => {
            return {
              ...prevState,
              error: response.error.message
            };
          });
        } else {
          const userData = {
            userId: response.localId,
            authToken: response.idToken,
            refreshToken: response.refreshToken,
            expiresAt: new Date().getTime() + response.expiresIn * 1000
          };
          props.loginUser(userData);
          navigate(ROUTE_ROOT);
        }
      });
  }

  const handleInputChange = (event) => {
    const fieldName = event.target.id;
    const fieldVal = event.target.value;

    setState(prevState => {
      return {
        ...prevState,
        [fieldName]: fieldVal
      };
    });
  }

  if (null !== JSON.parse(localStorage.getItem("user"))) {
    return <Navigate to={ ROUTE_ROOT }/>;
  }

  // update browser title
  document.title = 'Login';

  return (
    <main>
      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
          <form onSubmit={ loginRequest }>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input type="email" className="form-control" id="email" aria-describedby="emailHelp" onChange={ handleInputChange } required />
              <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input type="password" className="form-control" id="password" onChange={ handleInputChange } required />
            </div>
            { state.error.length > 0 &&
              <div className="alert alert-danger mb-3" role="alert">
                { state.error }
              </div> 
            }
            <div className="mb-3">
              <p>New here? <Link to={ ROUTE_REGISTRATION }>Create account</Link></p>
            </div>
            <div className="row justify-content-center">
              <div className="col-4 d-grid">
                <button type="submit" className="btn btn-outline-primary">Login</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );

}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // synchronous actions
    ...bindActionCreators(appActions, dispatch),
    // thunk actions
  }
}

export default connect(null, mapDispatchToProps)(Login);