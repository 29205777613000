import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { coinExists, pfExists, portfolioLoaded, pricesLoaded } from '../../core/app/selectors';
import { checkLoggedUser, fetchUserData } from '../../core/app/thunks';
import { DEFAULT_CURRENCY } from '../../core/constants';
import { ROUTE_COIN_DELETE, ROUTE_COIN_EDIT, ROUTE_LOGIN, ROUTE_TX_NEW } from '../../core/routes';
import './Coin.scss';

/*
 * Layout for Coin subpages / subroutes
 */
function Coin(props) {

  const { id, coinId } = useParams();
  const navigate = useNavigate();

  // Similar to componentDidMount
  useEffect(() => {
    props.checkLoggedUser();
    // load user's portfolio data in case of browser reload, it's needed in all subroutes anyway
    props.fetchUserData(false)
      .then(() => {
        // user data loaded
      });
  }, []);

  if (null === JSON.parse(localStorage.getItem("user"))) {
    navigate(ROUTE_LOGIN);
  }

  if (!(props.portfolioLoaded)) {
    return (<div>Loading...</div>);
  }

  if (!props.pfExists(id)) {
    return (<div className="error">Portfolio { id } does not exist!</div>);
  }

  if (coinId && !props.coinExists(id, coinId)) {
    return (<div className="error">Coin { coinId } does not exist in portfolio { props.portfolio[id].metadata.name }!</div>);
  }

  let logo = null;
  let title = coinId;
  if (props.portfolioLoaded && props.coinExists(id, coinId)) {
    const coin = props.portfolio[id].coins[coinId];
    title = coin.name + ' [' + coin.symbol + ']';
    logo = props.pricesLoaded && props.data.RAW.hasOwnProperty(coin.symbol)
      ? <img className="coin-logo" src={ "https://www.cryptocompare.com" + props.data.RAW[coin.symbol][DEFAULT_CURRENCY].IMAGEURL } alt={coin.symbol + " logo"} />
      : null;
  }

  return (
    <main className="coin-page">
      <div className="row">
        <div className="col-auto">
          <h2>{ logo }{ title }</h2>
        </div>
        <div className="col-auto ms-auto align-items-center d-flex">
          <Link to={ ROUTE_TX_NEW(id, coinId) } className="btn btn-outline-primary ms-3">
            <span role="img" aria-label="add">➕</span> New Transaction
          </Link>
          <Link to={ ROUTE_COIN_EDIT(id, coinId) } className="btn btn-outline-primary ms-3">
            <span role="img" aria-label="edit">✏️</span> Edit
          </Link>
          <Link to={ ROUTE_COIN_DELETE(id, coinId) } className="btn btn-outline-primary btn-icon ms-3">
            <span role="img" aria-label="delete">🗑️</span>
          </Link>
        </div>
      </div>

      <Outlet />

    </main>
  );
}

function mapStateToProps(state) {
  return {
    data: state.app.currentPrices,
    portfolio: state.app.portfolio,
    portfolioLoaded: portfolioLoaded(state),
    pricesLoaded: pricesLoaded(state),
    pfExists: (id) => pfExists(id)(state),
    coinExists: (id, coinId) => coinExists(id, coinId)(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // thunk actions
    checkLoggedUser: () => dispatch(checkLoggedUser),
    fetchUserData: (forceReload) => dispatch(fetchUserData(forceReload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Coin);
