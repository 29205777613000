import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchCurrentPrices } from '../core/app/thunks';
import { ROUTE_LOGIN, ROUTE_ROOT } from '../core/routes';

function Header(props) {
  const navigate = useNavigate();

  const handleclick = (event) => {
    if (props.userIsLogged) {
      props.fetchCurrentPrices();
      navigate(ROUTE_ROOT);
    } else {
      navigate(ROUTE_LOGIN);
    }
  }

  const loadingAnimation = () => {
    const {loading} = props;
    return (
      <span id="loading" className={ loading ? 'rotate' : '' }>w</span>
    )
  }

  return (
    <header>
      <h1>
        <span onClick={ handleclick }>Crypto{ loadingAnimation() }atcher</span>
      </h1>
    </header>
  )
}

function mapStateToProps(state) {
  return {
    loading: state.app.loading,
    userIsLogged: state.app.user.hasOwnProperty('userId')
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentPrices: () => dispatch(fetchCurrentPrices)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
