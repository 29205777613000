import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTE_COIN, ROUTE_ROOT } from '../../core/routes';
import { FIREBASE_DB_URL } from '../../core/constants';
import { buildFetchConfig } from '../../core/helpers';
import { fetchUserData } from '../../core/app/thunks';

function CoinDelete(props) {

  const { id, coinId } = useParams();
  const navigate = useNavigate();

  const coin = props.portfolio[id].coins[coinId];

  const deleteRequest = (event) => {
    event.preventDefault();

    // first move data into "_deleted_coins"
    let url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/portfolio/" 
      + id + "/_deleted_coins/" + coinId + ".json?auth=" + props.user.authToken;
    const data = {
      ...coin,
      _deleted: new Date().toISOString().substring(0, 10)
    };
    let config = buildFetchConfig('PUT', data);
    fetch(url, config)
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);

        // then actually delete and update in store
        url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/portfolio/" 
          + id + "/coins/" + coinId + ".json?auth=" + props.user.authToken;
        config = buildFetchConfig('DELETE');
        fetch(url, config)
          .then((response) => response.json())
          .then((responseData) => {
            if(responseData === null) {
              // successfully deleted
              props.fetchUserData(true);
              navigate(ROUTE_ROOT, { state: {selectedTab: id} });
            }
          });

      });      
  }

  const header = 'Delete coin';

  // update browser title
  document.title = header;

  const warning = coin.hasOwnProperty('transactions') && Object.keys(coin.transactions).length
    ? (
      <p className="text-center">
        including all it's { coin.hasOwnProperty('transactions') ? Object.keys(coin.transactions).length : 0 } transactions permanently?
        <br/><br/>
        Portfolio value will no longer reflect gains or losses contributed by this coin's transactions.
      </p>
    )
    : (
      <p className="text-center">permanently?</p>
    );

  return (
    <section className="coin-delete">
      <h2 className="text-center my-4">{ header }</h2>
      <div className="row justify-content-center my-4">
        <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
          <form onSubmit={ deleteRequest }>
            <div className="mb-3">
              <p className="text-center">
                Do you really want to delete coin
              </p>
              <div className="alert alert-danger mb-3" role="alert">
                <h3 className="text-center my-2">{ coin.name + ' [' + coin.symbol + ']' }</h3>
              </div>
              { warning }
            </div>
            <div className="row justify-content-center">
              <div className="col-4 d-grid">
                <button type="submit" className="btn btn-danger">
                  Delete
                </button>
              </div>
              <div className="col-4 d-grid">
                <Link to={ ROUTE_COIN(id, coinId) } className="btn btn-outline-secondary">
                  Back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

function mapStateToProps(state) {
  return {
    user: state.app.user,
    portfolio: state.app.portfolio,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // thunk actions
    fetchUserData: (forceReload) => dispatch(fetchUserData(forceReload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoinDelete);
