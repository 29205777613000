import React from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { checkLoggedUser, fetchUserData } from '../../core/app/thunks';
import Formatter from "../../core/Formatter"
import constants from '../../core/constants';
import { ROUTE_ROOT, ROUTE_TX_DELETE, ROUTE_TX_EDIT } from '../../core/routes';

function Detail(props) {
  const { id, coinId } = useParams();
  
  const coin = props.portfolio[id].coins[coinId];
  let transactions = [];
  for (const txId in coin.transactions) {
    const tx = coin.transactions[txId];
    transactions.push(
      <tr key={ tx.type + tx.date + tx.pieces }>
        <td>{ tx.date }</td>
        <td className="text-end">
          { Formatter.formatColor(tx.pieces * (tx.type === "buy" ? 1 : -1), '', constants.DECIMALS_PIECES, props.euDecimals) }
        </td>
        <td className="text-end">
          { Formatter.formatColor(tx.value * (tx.type === "buy" ? -1 : 1), props.portfolio[id].metadata.currency, null, props.euDecimals) }
        </td>
        <td>{ tx.hasOwnProperty('note') ? tx.note : '' }</td>
        <td className="text-end text-nowrap">
          <Link to={ ROUTE_TX_EDIT(id, coinId, txId) } className="btn btn-outline-secondary btn-xs">
            <span role="img" aria-label="edit">✏️</span>
          </Link>
          <Link to={ ROUTE_TX_DELETE(id, coinId, txId) } className="btn btn-outline-secondary btn-xs ms-2">
            <span role="img" aria-label="delete">🗑️</span>
          </Link>
        </td>
      </tr>
    );
  }

  // update browser title
  document.title = props.portfolio[id].metadata.name + ' > ' + coin.name + ' [' + coin.symbol + ']';

  return (
    <section className="coin-detail">
      <h2 className="text-center my-4">Transactions</h2>
      <div className="row">
        <div className="col-12">
          <table>
            <tbody>
              { transactions }
            </tbody>
          </table>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
          <div className="row justify-content-center my-3">
            <div className="col-4 d-grid">
              <Link to={ ROUTE_ROOT } state={ {selectedTab: id} } className="btn btn-outline-secondary">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function mapStateToProps(state) {
  return {
    portfolio: state.app.portfolio,
    euDecimals: state.app.settings.euDecimals,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // thunk actions
    checkLoggedUser: () => dispatch(checkLoggedUser),
    fetchUserData: (forceReload) => dispatch(fetchUserData(forceReload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
