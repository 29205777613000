import React, { useState } from 'react'
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official'
import { getCoinsOfPortfolio } from '../../core/app/selectors';
import { getTransactionsTotals } from '../../core/helpers';
import { CHART_HEIGHT, COLOR_RGBA_GREEN, COLOR_RGBA_RED, DEFAULT_CURRENCY } from '../../core/constants';
import './Chart.scss';

HighchartsMore(Highcharts);

const ProfitChart = (props) => {

  const [ days, setDays ] = useState(null);

  const pfCoins = props.coinsForChart(props.pfId);
  
  const getMaxDays = () => {
    let days = 0;
    for (const coin of Object.values(pfCoins)) {
      if (typeof props.prices[coin.symbol] === "object") {
        days = Math.max(days, Object.keys(props.prices[coin.symbol]).length);
      }
    }
    return days;
  };
  
  const getSeries = () => {
    let series = [];
    let totals = {};

    for (const coin of Object.values(pfCoins)) {
      // only if we have pricedata for this coin
      if (typeof props.prices[coin.symbol] === "object") {
        const coinDays = Object.keys(props.prices[coin.symbol]).length;
        if (coinDays > 0) {
          let data = [];
          const start = days ? Math.max(0, coinDays - days) : 0;
          for (let i = start; i < coinDays; i++) {
            const price = props.prices[coin.symbol][i];
            const timestamp = price.time * 1000;
            const { pieces, invested, withdrawn } = getTransactionsTotals(coin.transactions, timestamp);
            const value = price.close * pieces + withdrawn - invested;
            data.push({
              x: timestamp,
              y: value
            });
  
            // build total series
            if (typeof totals[timestamp] === 'undefined') {
              totals[timestamp] = {
                buy: 0,
                current: 0
              };
            }
            totals[timestamp].current = totals[timestamp].current + value;
          }
          series.push({
            visible: false,
            name: coin.symbol,
            type: 'line',
            data: data,
            zIndex: 1
          });
        }
      }
    }

    // sort total timespans, as keys were created in random order, given which coin was crawled first
    const timespans = Object.keys(totals).sort();
    // convert totals series to highcharts-more compatible array
    let totals_data = [];
    for (const timespan of timespans) {
      totals_data.push({
        x: parseInt(timespan, 10),
        low: totals[timespan].buy,
        high: totals[timespan].current,
        color: totals[timespan].buy < totals[timespan].current ? COLOR_RGBA_GREEN : COLOR_RGBA_RED,
        fillColor: totals[timespan].buy < totals[timespan].current ? COLOR_RGBA_GREEN : COLOR_RGBA_RED
      });
    }

    series.push({
      name: 'Total',
      //type: 'arearange',
      type: 'columnrange',
      //visible: false,
      data: totals_data,
      marker: {
        enabled: true,
        symbol: 'square'
      },
      zIndex: 0
    });
  
    return series;
  };
  
  const getConfig = () => {
    return {
      chart: {
        height: CHART_HEIGHT,
        zoomType: 'x'
      },
      plotOptions: {
        arearange: {
          dashStyle: 'Dot',
          color: COLOR_RGBA_GREEN,
          fillOpacity: 0.2,
          lineWidth: 1,
          marker: {
            enabled: false
          }
        },
        columnrange: {
          color: '#000000'
        },
        series: {
          // disable limit of 1000 data objects in points
          turboThreshold: 0
        }
      },
      title: null,
      tooltip: {
        valueDecimals: 2,
        valueSuffix: ' ' + DEFAULT_CURRENCY
      },
      xAxis: {
        title: {
          text: false
        },
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: 'Profit EUR'
        }
      },
      series: getSeries(),
    }

  };
  
  const getButtons = () => {
    const timespans = [
      {
        d: null,
        l: 'MAX'
      },
      {
        d: 1826,
        l: '5Y'
      },
      {
        d: 730,
        l: '2Y'
      },
      {
        d: 365,
        l: '1Y'
      },
      {
        d: 180,
        l: '6M'
      },
      {
        d: 90,
        l: '3M'
      },
      {
        d: 30,
        l: '1M'
      },
      {
        d: 7,
        l: '1W'
      },
    ];
    const maxDays = getMaxDays();
    let buttons = [];
    for (let ts of timespans) {
      // show only MAX timeframe & timeframes shorter than the portfolio age
      if (!ts.d || ts.d < maxDays) {
        const btnClass = 'btn btn-' + (days === ts.d ? 'secondary' : 'outline-secondary');
        buttons.push(
          <button key={ ts.d } onClick={ (e) => setDays(ts.d) } className={ btnClass }>
            { ts.l }
          </button>
        );
      }
    }
    return buttons;
  }

  if (pfCoins && props.prices) {
    return (
      <div className="crypto-chart">
        <h2 className="my-4">Profit Chart</h2>
        <div className="tabs">
          { getButtons() }
        </div>
        <HighchartsReact
          highcharts={ Highcharts }
          options={ getConfig() }
        />
      </div>
    );
  } else {
    return (
      <div className="loading">
        Loading data for chart…
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    coinsForChart: (pfId) => getCoinsOfPortfolio(pfId)(state),
    prices: state.app.histories.EUR,
  }
}

export default connect(mapStateToProps)(ProfitChart);