import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import './App.scss';
import rootReducer from './core/reducers';
import * as routes from './core/routes';
import Layout from './Layout';
import Home from './components/Home';
import Login from './components/User/Login';
import Registration from './components/User/Registration';
import PortfolioForm from './components/Portfolio/PortfolioForm';
import PortfolioDelete from './components/Portfolio/PortfolioDelete';
import NewCoin from './components/Coin/NewCoin';
import Coin from './components/Coin/Coin';
import Detail from './components/Coin/Detail';
import CoinForm from './components/Coin/CoinForm';
import CoinDelete from './components/Coin/CoinDelete';
import TransactionForm from './components/Coin/TransactionForm';
import TransactionDelete from './components/Coin/TransactionDelete';

const composedEnhancer = composeWithDevTools(
  applyMiddleware(thunkMiddleware)
)

// The store now has the ability to accept thunk functions in `dispatch`
const store = createStore(rootReducer, composedEnhancer);

function App(props) {
  return (
    <Provider store={ store }>
      <BrowserRouter>
        <Routes>
          <Route path={ routes.ROUTE_ROOT } element={ <Layout /> }>
            <Route index element={ <Home /> } />
            <Route path={ routes.ROUTE_LOGIN.substring(1) } element={ <Login /> } />
            <Route path={ routes.ROUTE_REGISTRATION.substring(1) } element={ <Registration /> } />
            <Route path={ routes.ROUTE_PF_EDIT().substring(1) } element={ <PortfolioForm /> } />
            <Route path={ routes.ROUTE_PF_NEW.substring(1) } element={ <PortfolioForm /> } />
            <Route path={ routes.ROUTE_PF_DELETE().substring(1) } element={ <PortfolioDelete /> } />
            <Route path={ routes.ROUTE_COIN_NEW().substring(1) } element={ <NewCoin /> } />

            <Route path={ routes.ROUTE_COIN().substring(1) } element={ <Coin /> }>
              <Route index element={ <Detail /> } />
              <Route path={ routes.SEGMENT_EDIT } element={ <CoinForm /> } />
              <Route path={ routes.SEGMENT_DELETE } element={ <CoinDelete /> } />
              <Route path={ routes.SEGMENT_ADD } element={ <TransactionForm /> } />
              <Route path={ routes.SEGMENT_TX_ID() } element={ <TransactionForm /> } />
              <Route path={ routes.SEGMENT_TX_ID() + '/' + routes.SEGMENT_DELETE } element={ <TransactionDelete /> } />
            </Route>

            { /* fallback */ }
            <Route path='*' element={<Navigate to={ routes.ROUTE_ROOT } />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );

}

export default App;
