import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { pfExists, portfolioLoaded } from '../../core/app/selectors';
import { checkLoggedUser, fetchUserData } from '../../core/app/thunks';
import { ROUTE_LOGIN } from '../../core/routes';
import CoinForm from './CoinForm';

/*
 * Layout for New Coin, using CoinForm for logic
 */
function NewCoin(props) {

  const { id } = useParams();
  const navigate = useNavigate();

  // Similar to componentDidMount
  useEffect(() => {
    props.checkLoggedUser();
    // load user's portfolio data in case of browser reload
    props.fetchUserData(false)
      .then(() => {
        // user data loaded
      });
  }, []);

  if (null === JSON.parse(localStorage.getItem("user"))) {
    navigate(ROUTE_LOGIN);
  }

  if (!(props.portfolioLoaded)) {
    return (<div>Loading...</div>);
  }

  if (!props.pfExists(id)) {
    return (<div className="error">Portfolio { id } does not exist!</div>);
  }

  return (
    <CoinForm />
  );
}

function mapStateToProps(state) {
  return {
    portfolioLoaded: portfolioLoaded(state),
    pfExists: (id) => pfExists(id)(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // thunk actions
    checkLoggedUser: () => dispatch(checkLoggedUser),
    fetchUserData: (forceReload) => dispatch(fetchUserData(forceReload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCoin);
