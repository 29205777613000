import * as types from './action-types';

export function loadingStart() {
  return {
    type: types.LOADING_START
  }
}

export function loadingEnd() {
  return {
    type: types.LOADING_END
  }
}

export function updateCurrentPrices(payload) {
  return {
    type: types.UPDATE_CURRENT_PRICES,
    payload
  }
}

export function updateHistories(payload) {
  return {
    type: types.UPDATE_HISTORIES,
    payload
  }
}

export function initUser(payload) {
  return {
    type: types.USER_INIT,
    payload
  }
}

export function loginUser(payload) {
  return {
    type: types.USER_LOGIN,
    payload
  }
}

export function loadUser() {
  return {
    type: types.USER_LOAD
  }
}

export function logoutUser() {
  return {
    type: types.USER_LOGOUT
  }
}

export function changeUserSettings(payload) {
  return {
    type: types.USER_SETTINGS_CHANGE,
    payload
  }
}
