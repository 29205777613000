import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTE_LOGIN, ROUTE_ROOT } from '../../core/routes';
import { FIREBASE_DB_URL } from '../../core/constants';
import { buildFetchConfig } from '../../core/helpers';
import { checkLoggedUser, fetchUserData } from '../../core/app/thunks';
import { pfExists, portfolioLoaded } from '../../core/app/selectors';

function PortfolioDelete(props) {

  const { id } = useParams();
  const navigate = useNavigate();

  // Similar to componentDidMount
  useEffect(() => {
    props.checkLoggedUser();
    // load user's portfolio data in case of browser reload
    props.fetchUserData(false)
      .then(() => {
        // user data loaded
      });
  }, []);

  if (null === JSON.parse(localStorage.getItem("user"))) {
    navigate(ROUTE_LOGIN);
  }

  if (!(props.portfolioLoaded)) {
    return (<div>Loading...</div>);
  }

  if (!props.pfExists(id)) {
    return (<div className="error">Portfolio { id } does not exist!</div>);
  }

  const pf = props.portfolio[id];

  const deleteRequest = (event) => {
    event.preventDefault();

    // first move data into "_deleted_portfolio"
    let url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/_deleted_portfolio/" 
      + id + ".json?auth=" + props.user.authToken;
    const data = {
      ...pf,
      _deleted: new Date().toISOString().substring(0, 10)
    };
    let config = buildFetchConfig('PUT', data);
    fetch(url, config)
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);

        // then actually delete and update in store
        url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/portfolio/" 
          + id + ".json?auth=" + props.user.authToken;
        config = buildFetchConfig('DELETE');
        fetch(url, config)
          .then((response) => response.json())
          .then((responseData) => {
            if(responseData === null) {
              // successfully deleted
              props.fetchUserData(true);
              navigate(ROUTE_ROOT);
            }
          });

      });      
  }

  const header = 'Delete portfolio';

  // update browser title
  document.title = header;

  return (
    <section className="pf-delete">
      <h2 className="text-center my-4">{ header }</h2>
      <div className="row justify-content-center my-4">
        <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
          <form onSubmit={ deleteRequest }>
            <div className="mb-3">
              <p className="text-center">
                Do you really want to delete portfolio
              </p>
              <div className="alert alert-danger mb-3" role="alert">
                <h3 className="text-center my-2">{ pf.metadata.name }</h3>
              </div>
              <p className="text-center">
                { pf.hasOwnProperty('coins') && Object.keys(pf.coins).length ? "including all it's " + Object.keys(pf.coins).length + " coins " : '' }permanently?
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-4 d-grid">
                <button type="submit" className="btn btn-danger">
                  Delete
                </button>
              </div>
              <div className="col-4 d-grid">
                <Link to={ ROUTE_ROOT } state={ {selectedTab: id} } className="btn btn-outline-secondary">
                  Back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

function mapStateToProps(state) {
  return {
    user: state.app.user,
    portfolio: state.app.portfolio,
    portfolioLoaded: portfolioLoaded(state),
    pfExists: (id) => pfExists(id)(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // thunk actions
    fetchUserData: (forceReload) => dispatch(fetchUserData(forceReload)),
    checkLoggedUser: () => dispatch(checkLoggedUser),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioDelete);
