import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTE_COIN } from '../../core/routes';
import { FIREBASE_DB_URL } from '../../core/constants';
import { buildFetchConfig } from '../../core/helpers';
import { fetchUserData } from '../../core/app/thunks';
import Formatter from '../../core/Formatter';

function TransactionDelete(props) {

  const { id, coinId, txId } = useParams();
  const navigate = useNavigate();

  const tx = props.portfolio[id].coins[coinId].transactions[txId];

  const deleteRequest = (event) => {
    event.preventDefault();

    // first move data into "_deleted_transactions"
    let url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/portfolio/" 
      + id + "/coins/" + coinId + "/_deleted_transactions/" + txId + ".json?auth=" + props.user.authToken;
    const data = {
      ...tx,
      _deleted: new Date().toISOString().substring(0, 10)
    };
    let config = buildFetchConfig('PUT', data);
    fetch(url, config)
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);

        // then actually delete and update in store
        url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/portfolio/" 
          + id + "/coins/" + coinId + "/transactions/" + txId + ".json?auth=" + props.user.authToken;
        config = buildFetchConfig('DELETE');
        fetch(url, config)
          .then((response) => response.json())
          .then((responseData) => {
            if(responseData === null) {
              // successfully deleted
              props.fetchUserData(true);
              navigate(ROUTE_COIN(id, coinId));
            }
          });

      });      
  }

  const header = 'Delete transaction';

  // update browser title
  document.title = header;

  return (
    <section className="transaction-delete">
      <h2 className="text-center my-4">{ header }</h2>
      <div className="row justify-content-center my-4">
        <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
          <form onSubmit={ deleteRequest }>
            <div className="mb-3">
              <p className="text-center">
                Do you really want to delete this transaction in <strong>{ props.portfolio[id].coins[coinId].name }</strong>?
              </p>
              <table className="mb-3">
                <tbody>
                  <tr>
                    <td>Type</td>
                    <td>{ tx.type }</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>{ tx.date }</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>{ Formatter.format(tx.pieces, props.portfolio[id].coins[coinId].symbol, null, props.euDecimals) }</td>
                  </tr>
                  <tr>
                    <td>Value</td>
                    <td>{ Formatter.format(tx.value, props.portfolio[id].metadata.currency, null, props.euDecimals) }</td>
                  </tr>
                  <tr>
                    <td>Note</td>
                    <td>{ tx.note }</td>
                  </tr>
                </tbody>
              </table>
              <p className="text-center">
                This change will be reflected permanently in portfolio value, like this transaction never existed before.
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-4 d-grid">
                <button type="submit" className="btn btn-danger">
                  Delete
                </button>
              </div>
              <div className="col-4 d-grid">
                <Link to={ ROUTE_COIN(id, coinId) } className="btn btn-outline-secondary">
                  Back
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

function mapStateToProps(state) {
  return {
    user: state.app.user,
    portfolio: state.app.portfolio,
    euDecimals: state.app.settings.euDecimals,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // thunk actions
    fetchUserData: (forceReload) => dispatch(fetchUserData(forceReload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDelete);
