import React from 'react';
import { connect } from 'react-redux';
import { appActions } from '../../core/app';

function LogoutButton(props) {
  const handleclick = (event) => {
    props.logoutUser();
  }

  return (
    <button className="btn btn-outline-secondary logout-button" onClick={ handleclick }>
      <span>logout</span>
    </button>
  );
}

export default connect(null, appActions)(LogoutButton);
