import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Formatter from '../../core/Formatter';
import { checkLoggedUser, fetchUserData } from '../../core/app/thunks';
import { txExists } from '../../core/app/selectors';
import { ROUTE_COIN } from '../../core/routes';
import { FIREBASE_DB_URL, MAX_DECIMALS } from '../../core/constants';
import { buildFetchConfig } from '../../core/helpers';

function TransactionForm(props) {

  const today = new Date().toISOString().substring(0, 10);
  const baseState = {
    // mandatory
    type: "buy",
    date: today,
    pieces: 0,
    value: 0,
    // optional
    price: 0,
    fee: 0,
    note: "",
  };
  const [ state, setState ] = useState(baseState);
  const { id, coinId, txId } = useParams();
  const navigate = useNavigate();

  // const showDelete = useMatch(ROUTE_TX_DELETE(id, coinId, txId));

  const elPieces = useRef(null);

  // will run when value of "txExists" changes
  useEffect(() => {
    // load all form fields from store in case nothing has been entered yet
    if (props.txExists(id, coinId, txId)) {
      const tx = props.portfolio[id].coins[coinId].transactions[txId];
      setState(prevState => {
        let price = tx.price ? tx.price : prevState.price;
        if (price == 0 && tx.value > 0 && tx.pieces > 0) {
          price = tx.value / tx.pieces;
        }
        return {
          ...prevState,
          type: tx.type,
          date: tx.date,
          pieces: Formatter.precisionRound(tx.pieces, MAX_DECIMALS, props.euDecimals),
          value: Formatter.precisionRound(tx.value, MAX_DECIMALS, props.euDecimals),
          price: Formatter.precisionRound(price, MAX_DECIMALS, props.euDecimals),
          fee: Formatter.precisionRound(tx.fee ? tx.fee : prevState.fee, MAX_DECIMALS, props.euDecimals),
          note: tx.note ? tx.note : prevState.note,
        };
      });
    }
  }, [props.txExists(id, coinId, txId)]);

  // on the fly validation if state changes or HTML element gets created
  useEffect(() => {
    validatePieces();
  }, [state.pieces, elPieces.current]);

  const transactionRequest = (event) => {
    event.preventDefault();
    console.log(txId);
    const data = {
      // mandatory fields
      type: state.type,
      date: state.date,
      pieces: Formatter.parseNum(state.pieces, props.euDecimals),
      value: Formatter.parseNum(state.value, props.euDecimals),
      // optional fields
      price: Formatter.parseNum(state.price, props.euDecimals),
      fee: Formatter.parseNum(state.fee, props.euDecimals),
      note: state.note,
    };

    if (txId) {
      // existing - update
      const url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/portfolio/" 
        + id + "/coins/" + coinId + "/transactions/" + txId + ".json?auth=" + props.user.authToken;
      const config = buildFetchConfig('PATCH', data);
        fetch(url, config)
        .then((response) => response.json())
        .then((responseData) => {
          console.log(responseData);
          props.fetchUserData(true);
          navigate(ROUTE_COIN(id, coinId));
        });      
    } else {
      // new - create
      const url = FIREBASE_DB_URL + "/users/" + props.user.userId + "/portfolio/" 
        + id + "/coins/" + coinId + "/transactions.json?auth=" + props.user.authToken;
      const config = buildFetchConfig('POST', data);
      fetch(url, config)
        .then((response) => response.json())
        .then((responseData) => {
          console.log(responseData);
          props.fetchUserData(true);
          if (responseData.hasOwnProperty('name')) {
            console.log('transation ' + responseData.name + ' created in firebase');
            navigate(ROUTE_COIN(id, coinId));
          }
        });
    }
  }

  const handleInputChange = (event) => {
    const fieldName = event.target.id;
    const fieldVal = event.target.value;

    setState(prevState => {
      return {
        ...prevState,
        [fieldName]: fieldVal
      };
    });

    // pieces * price = value logic
    switch (fieldName) {
      case 'pieces':
      case 'value':
        // calculate price
        setState(prevState => {
          const parsedVal = Formatter.parseNum(prevState.value, props.euDecimals);
          const parsedPieces = Formatter.parseNum(prevState.pieces, props.euDecimals);
          const price = parsedPieces > 0 ? parsedVal / parsedPieces : 0;
          return {
            ...prevState,
            price: Formatter.precisionRound(price, MAX_DECIMALS, props.euDecimals)
          };
        });
        break;
      case 'price':
        // calculate value
        setState(prevState => {
          const parsedPrice = Formatter.parseNum(prevState.price, props.euDecimals);
          const parsedPieces = Formatter.parseNum(prevState.pieces, props.euDecimals);
          return {
            ...prevState,
            value: Formatter.precisionRound(parsedPrice * parsedPieces, MAX_DECIMALS, props.euDecimals)
          };
        });
        break;
    }
  }

  const validatePieces = () => {
    if (elPieces.current) {
      const valid = Formatter.parseNum(state.pieces, props.euDecimals) > 0;
      elPieces.current.setCustomValidity(valid ? '' : 'Value must be grater than zero.');
    }
  }

  if (txId && !props.txExists(id, coinId, txId)) {
    return (<div className="error">Transaction { txId } does not exist!</div>);
  }

  // show delete form if DELETE segment is present in route
  // if(showDelete) {
  //   return <TransactionDelete />
  // }

  const button = txId ? 'Update' : 'Create';
  const header = txId ? 'Edit transaction' : 'New transaction';

  // update browser title
  document.title = header;

  // calculations
  const parsedVal = Formatter.parseNum(state.value, props.euDecimals);
  const parsedFee = Formatter.parseNum(state.fee, props.euDecimals);
  const totalVal = Formatter.precisionRound(1 * parsedVal + (state.type === "buy" ? 1 : -1) * parsedFee, null, props.euDecimals);

  const localizedFloat = '^-?[0-9]+' + (props.euDecimals ? ',' : '\\.') + '?[0-9]*$';
  const formatHelp = 'Only digits & one decimal separator ( ' + (props.euDecimals ? ',' : '.') + ' ) enabled';

  return (
    <section className="coin-edit">
      <h2 className="text-center my-4">{ header }</h2>
      <div className="row justify-content-center my-4">
        <div className="col-md-12 col-lg-10 col-xl-8 col-xxl-6">
          <form onSubmit={ transactionRequest }>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="type" className="form-label">Type *</label>
                <Form.Select id="type" aria-label="type" aria-describedby="typeHelp"
                  value={ state.type } onChange={ handleInputChange }>
                  <option value="buy">Buy</option>
                  <option value="sell">Sell</option>
                </Form.Select>
                <div id="typeHelp" className="form-text">Select buy if you exchange { props.portfolio[id].metadata.currency } for { props.portfolio[id].coins[coinId].symbol } and sell otherwise.</div>
              </div>
              <div className="col-md-6">
                <label htmlFor="date" className="form-label">Date *</label>
                <input type="date" className="form-control" id="date" aria-describedby="dateHelp" required
                  value={ state.date } onChange={ handleInputChange } />
                <div id="dateHelp" className="form-text">Date of transaction.</div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="pieces" className="form-label">Amount ({ props.portfolio[id].coins[coinId].symbol }) *</label>
                <input type="text" className="form-control" id="pieces" aria-describedby="piecesHelp" required
                  ref={ elPieces } value={ state.pieces } onChange={ handleInputChange } pattern={ localizedFloat } title={ formatHelp } />
                <div id="piecesHelp" className="form-text">Amount of tokens { state.type === "buy" ? "bought" : "sold" }.</div>
              </div>
              <div className="col-md-6">
                <label htmlFor="price" className="form-label">Price ({ props.portfolio[id].metadata.currency })</label>
                <input type="text" className="form-control" id="price" aria-describedby="priceHelp" required
                  value={ state.price } onChange={ handleInputChange } pattern={ localizedFloat } title={ formatHelp } />
                <div id="priceHelp" className="form-text">Price at the time of { state.type }. Informative only, will be calculated from Value / Amount</div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="value" className="form-label">Value ({ props.portfolio[id].metadata.currency }) *</label>
                <input type="text" className="form-control" id="value" aria-describedby="valueHelp" required
                  value={ state.value } onChange={ handleInputChange } pattern={ localizedFloat } title={ formatHelp } />
                <div id="valueHelp" className="form-text">{ state.type === "buy" ? "How much did you pay for the tokens" : "Value of the tokens at the time of sell" }. Amount multiplied by price.</div>
              </div>
              <div className="col-md-4">
                <label htmlFor="fee" className="form-label">Fee ({ props.portfolio[id].metadata.currency })</label>
                <input type="text" className="form-control" id="fee" aria-describedby="feeHelp"
                  value={ state.fee } onChange={ handleInputChange } pattern={ localizedFloat } title={ formatHelp } />
                <div id="feeHelp" className="form-text">Money spent on fees, { state.type === "buy" ? "not included in" : "will be deducted from" } the Value.</div>
              </div>
              <div className="col-md-4">
                <label htmlFor="total" className="form-label">Total ({ props.portfolio[id].metadata.currency })</label>
                <input type="text" className="form-control" id="total" aria-describedby="totalHelp" disabled readOnly
                  value={ totalVal } />
                <div id="totalHelp" className="form-text">Total money { state.type === "buy" ? "spent in" : "received ex" }cluding fees.</div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="note" className="form-label">Note</label>
              <textarea className="form-control" id="note" aria-describedby="noteHelp" rows="8"
                value={ state.note } onChange={ handleInputChange } />
              <div id="noteHelp" className="form-text">Your personal notes about the transaction.</div>
            </div>
            <div className="row justify-content-center">
              <div className="col-4 d-grid">
                <button type="submit" className="btn btn-outline-primary">
                  { button }
                </button>
              </div>
              <div className="col-4 d-grid">
                <Link to={ ROUTE_COIN(id, coinId) } className="btn btn-outline-secondary">Back</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

function mapStateToProps(state) {
  return {
    user: state.app.user,
    portfolio: state.app.portfolio,
    euDecimals: state.app.settings.euDecimals,
    txExists: (id, coinId, txId) => txExists(id, coinId, txId)(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // thunk actions
    checkLoggedUser: () => dispatch(checkLoggedUser),
    fetchUserData: (forceReload) => dispatch(fetchUserData(forceReload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionForm);
