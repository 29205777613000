import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import DarkModeToggle from "react-dark-mode-toggle";
import constants from '../core/constants';
import { ROUTE_LOGIN } from '../core/routes';
import { updateHistories } from '../core/app/actions';
import { getAllCoinsHistoryDays } from '../core/app/selectors';
import { checkLoggedUser, fetchUserData, saveUserSettings } from '../core/app/thunks';
import { diffDays } from '../core/helpers';
import './Home.scss';
import PortfolioTabs from './Portfolio/PortfolioTabs';
import RefreshButton from './Buttons/RefreshButton';
import LogoutButton from './Buttons/LogoutButton';

function Home(props) {

  const [ historyRequested, setHistoryRequested ] = useState(false);
  const [ hpCount, setHPCount ] = useState(0);
  const [ hpBTCCount, setHPBTCCount ] = useState(0);
  const [ hp, setHP ] = useState({});
  const [ hpConversion, setHPConversion ] = useState({});
  const [ hpBTC, setHPBTC ] = useState({});

  // Similar to componentDidMount - will run only once
  useEffect(() => {
    props.checkLoggedUser();
    // load user's portfolio data in case not loaded already
    props.fetchUserData(false)
      .then(() => {
        // get history data for each coin (for chart)
        fetchHistories();
      });
  }, []);

  // trigger histories loading if coin list has been changed in state
  useEffect(() => {
    // get history data for each coin (for chart)
    fetchHistories();
  }, [props.allCoinsHistoryDays]);

  // every time some coin history is loaded, check if we have all and if yes, finally send it to the chart
  useEffect(() => {
    // convert from USD to EUR once history prices for all funds including the conversion currency histories are loaded
    if (hpCount > 0 && hpCount === Object.keys(hp).length && Object.keys(hpConversion).length > 0) {
      // create conversion array
      let conversionRates = {};
      for (let price of hpConversion[constants.CONVERSION_CURRENCY]) {
        conversionRates[price.time] = price.close;
      }
      for (const symbol in hp) {
        for (const key in hp[symbol]) {
          hp[symbol][key].close *= conversionRates[hp[symbol][key].time];
        }
      }
      props.updateHistories({
        currency: 'EUR',
        prices: hp,
      });
      // cleanup state variables - not needed anymore
      setHPCount(0);
      setHP({});
      setHPConversion({});
    }   
  }, [hp, hpConversion, hpCount]);

  // every time some coin BTC history is loaded, check if we have all and if yes, finally send it to the chart
  useEffect(() => {
    if (hpBTCCount > 0 && hpBTCCount === Object.keys(hpBTC).length) {
      props.updateHistories({
        currency: 'BTC',
        prices: hpBTC,
      });
      // cleanup state variables - not needed anymore
      setHPBTCCount(0);
      setHPBTC({});
    }
  }, [hpBTC, hpBTCCount]);

  const fetchHistories = () => {
    // only start again if not already started before and if list of coins is ready
    if (!historyRequested && props.allCoinsHistoryDays.length) {
      // remember to not do this again!
      setHistoryRequested(true);

      // base currency histories
      let maxDays = 0;
      for (const [symbol, historyDays] of props.allCoinsHistoryDays) {
        // fetch history since the date of first transaction
        if (historyDays > 0) {
          maxDays = Math.max(maxDays, historyDays);
          setHPCount(prevCount => prevCount + 1);
          fetchHistory(symbol, constants.CONVERSION_CURRENCY, historyDays, '_BASE');
        }
      }

      // histories of conversion currency
      fetchHistory(constants.CONVERSION_CURRENCY, constants.DEFAULT_CURRENCY, maxDays, '_CONVERSION');  

      // BTC histories
      const days = 90;
      for (const [symbol, historyDays] of props.allCoinsHistoryDays) {
        if (symbol !== 'BTC') {
          setHPBTCCount(prevCount => prevCount + 1);
          fetchHistory(symbol, 'BTC', days, '_BTC');
        }
      }
    }
  }

  const fetchHistory = (fromSymbol, toSymbol, historyDays, targetObjectName) => {
    if (historyDays > 0) {
      targetObjectName = targetObjectName ? targetObjectName : '_BASE';
      // let url = 'https://min-api.cryptocompare.com/data/v2/histoday?fsym=' + fromSymbol + '&tsym=' + toSymbol + '&limit=' + historyDays;
      let date = new Date();
      let url = 'https://crypto.dacko.sk/api/history.php?f=' + fromSymbol + '&t=' + toSymbol + '&d=' + historyDays + '&u=' + date.getTime();
      if (targetObjectName === '_BTC') {
        url += '&c=1';
      }
      fetch(url)
        .then((responseText) => responseText.json())
        .then((response) => {
          let data = response && response.hasOwnProperty('Data') && response.Data.hasOwnProperty('Data') ? response.Data.Data : [];
          // divide closing prices for VET by 100 if it is greater than 0.5 (due to errors in data from price API)
          if (fromSymbol === 'VET') {
            data = fixDataErrors(data, 0.5, 0.01);
          }

          data = reduceDataForChart(data);

          switch (targetObjectName) {
            case '_BASE':
              if (data.length) {
                setHP(prevState => {
                  return {
                    ...prevState,
                    [fromSymbol]: data,
                  };
                });
              } else {
                // no data - don't add it to state and don't count with it
                setHPCount(prevCount => prevCount - 1);
              }
              break;
            case'_CONVERSION':
              setHPConversion(prevState => {
                return {
                  ...prevState,
                  [fromSymbol]: data,
                };
              });
              break;
            case '_BTC':
              if (data.length) {
                setHPBTC(prevState => {
                  return {
                    ...prevState,
                    [fromSymbol]: data,
                  };
                });  
              } else {
                // no data - don't add it to state and don't count with it
                setHPBTCCount(prevCount => prevCount - 1);
              }
          }
        });
    }
  }

  const handleThemeChange = (value) => {
    const settings = {
      ...props.settings,
      dark: value
    }
    props.saveUserSettings(settings);
  }

  const fixDataErrors = (data, breakpoint, multiplicationFactor) => {
    breakpoint = breakpoint || 0.5;
    multiplicationFactor = multiplicationFactor || 0.01;
    for (const key in data) {
      if (data[key].close >= breakpoint) {
        data[key].close *= multiplicationFactor;
      }
    }
    return data;
  }

  const reduceDataForChart = (data) => {
    // reduce data - keep only close price & timestamp
    for (const key in data) {
      data[key] = {
        close: data[key].close,
        time: data[key].time,
      }
    }
    return data;
  }

  if (null === JSON.parse(localStorage.getItem("user"))) {
    return <Navigate to={ ROUTE_LOGIN }/>;
  }

  // update browser title
  document.title = 'Home';

  return (
    <main>
      <div className="top-right">
        <DarkModeToggle className="dark-switch" onChange={ handleThemeChange } checked={ props.settings.dark } size={ 68 } />
        <RefreshButton />
        <LogoutButton />
      </div>

      <PortfolioTabs />

    </main>
  );

}

function mapStateToProps(state) {
  return {
    settings: state.app.settings,
    allCoinsHistoryDays: getAllCoinsHistoryDays(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // synchronous actions
    updateHistories: (payload) => dispatch(updateHistories(payload)),
    // thunk actions
    fetchUserData: (forceReload) => dispatch(fetchUserData(forceReload)),
    saveUserSettings: (settings) => dispatch(saveUserSettings(settings)),
    checkLoggedUser: () => dispatch(checkLoggedUser),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
