import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { appActions } from '../../core/app';
import { createNewPortfolio } from '../../core/app/thunks';
import { ROUTE_LOGIN, ROUTE_ROOT } from '../../core/routes';
import { buildFetchConfig } from '../../core/helpers';

function Registration(props) {

  const baseState = {
    email: '',
    password1: '',
    password2: '',
    error: ''
  };
  const [ state, setState ] = useState(baseState);
  const navigate = useNavigate();

  const registrationRequest = () => {
    const FIREBASE_AUTH_URL = "https://identitytoolkit.googleapis.com/v1/accounts";
    const FIREBASE_API_KEY = "AIzaSyC11of8XwtXnG41_-TUJO8UAEECTSDOmh4";
    const url = FIREBASE_AUTH_URL + ":signUp?key=" + FIREBASE_API_KEY;
    const data = {
      email: state.email,
      password: state.password1,
      returnSecureToken: true
    };
    const config = buildFetchConfig('POST', data);

    fetch(url, config)
      .then((responseText) => responseText.json())
      .then((response) => {
        console.log(response);
        if (response.hasOwnProperty('error') && response.error.hasOwnProperty('message')) {
          console.log(response.error.message);
          setState(prevState => {
            return {
              ...prevState,
              error: response.error.message
            };
          });
        } else if (response.hasOwnProperty('localId')) {
          const userData = {
            userId: response.localId,
            authToken: response.idToken,
            refreshToken: response.refreshToken,
            expiresAt: new Date().getTime() + response.expiresIn * 1000
          };
          props.loginUser(userData);

          props.createNewPortfolio()
            .then(() => {
              navigate(ROUTE_ROOT);
            });
        }
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // validate inputs
    if (state.password1 !== state.password2) {
      setState(prevState => {
        return {
          ...prevState,
          error: 'Passwords does not match'
        };
      });
      return false;
    }
    // reset error
    const error = '';
    setState(prevState => {
      return {
        ...prevState,
        error
      };
    });
    // API call
    registrationRequest();
  }

  const handleInputChange = (event) => {
    const fieldName = event.target.id;
    const fieldVal = event.target.value;

    setState(prevState => {
      return {
        ...prevState,
        [fieldName]: fieldVal
      };
    });
  }

  if (null !== JSON.parse(localStorage.getItem("user"))) {
    return <Navigate to={ ROUTE_ROOT }/>;
  }

  // update browser title
  document.title = 'Registration';

  return (
    <main>
      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
          <form onSubmit={ handleSubmit }>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input type="email" className="form-control" id="email" aria-describedby="emailHelp" onChange={ handleInputChange } required />
              <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
            </div>
            <div className="mb-3">
              <label htmlFor="password1" className="form-label">Password</label>
              <input type="password" className="form-control" id="password1" aria-describedby="passHelp" onChange={ handleInputChange } required />
              <div id="passHelp" className="form-text">Password must be at least 6 characters long</div>
            </div>
            <div className="mb-3">
              <label htmlFor="password2" className="form-label">Password (repeat)</label>
              <input type="password" className="form-control" id="password2" onChange={ handleInputChange } required />
            </div>
            { state.error.length > 0 &&
              <div className="alert alert-danger mb-3" role="alert">
                { state.error }
              </div> 
            }
            <div className="mb-3">
              <p>Already have account? Try to <Link to={ ROUTE_LOGIN }>login</Link> instead</p>
            </div>
            <div className="row justify-content-center">
              <div className="col-6 d-grid">
              <button type="submit" className="btn btn-outline-primary">Create account</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );

}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // synchronous actions
    ...bindActionCreators(appActions, dispatch),
    // thunk actions
    createNewPortfolio: (data) => dispatch(createNewPortfolio(data)),
  }
}

export default connect(null, mapDispatchToProps)(Registration);
