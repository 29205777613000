import { diffDays, getTransactionsTotals } from "../helpers";

export const getAllCoins = state => {
    const coins = {};
    for (const pf of Object.values(state.app.portfolio)) {
        for (const coin of Object.values(pf.coins)) {
            // find how many days is the coin in the portfolio
            let maxDays = 0;
            if (coin.transactions && Object.keys(coin.transactions).length) {
                const firstTxId = Object.keys(coin.transactions)[0];
                const days = diffDays(coin.transactions[firstTxId].date) - 1;
                maxDays = Math.max(days, 0);    
            }
            // if coin/symbol was already added to list in another portfolio, take the older (higher number of days)
            if (coins.hasOwnProperty(coin.symbol)) {
                maxDays = Math.max(maxDays, coins[coin.symbol]);    
            }
            coins[coin.symbol] = maxDays;
        }    
    }
    return coins;
};

export const getAllSymbols = state => {
    const coins = getAllCoins(state);
    return Object.keys(coins);
};

export const getAllCoinsHistoryDays = state => {
    const coins = getAllCoins(state);
    return Object.entries(coins);
};

export const getCoinsOfPortfolio = pfId => state => {
    if (pfExists(pfId)(state) && state.app.portfolio[pfId].hasOwnProperty('coins')) {
        return state.app.portfolio[pfId].coins;
    }
    return null;
};

export const getCoinTransactionsTotals = (pfId, coinId, atTimestamp) => state => {
    const transactions = coinExists(pfId, coinId)(state) && state.app.portfolio[pfId].coins[coinId].hasOwnProperty('transactions')
        ? state.app.portfolio[pfId].coins[coinId].transactions
        : {};
    return getTransactionsTotals(transactions, atTimestamp)
}

export const userIsLogged = state => state.app.user.hasOwnProperty('userId');

export const portfolioLoaded = state => Object.keys(state.app.portfolio).length > 0;

export const pricesLoaded = state => Object.keys(state.app.currentPrices).length > 0;

export const pfExists = pfId => state => {
    return state.app.portfolio.hasOwnProperty(pfId);
};

export const coinExists = (pfId, coinId) => state => {
    return pfExists(pfId)(state) 
        && state.app.portfolio[pfId].hasOwnProperty('coins') 
        && state.app.portfolio[pfId].coins.hasOwnProperty(coinId);
};

export const txExists = (pfId, coinId, txId) => state => {
    return coinExists(pfId, coinId)(state) 
        && state.app.portfolio[pfId].coins[coinId].hasOwnProperty('transactions') 
        && state.app.portfolio[pfId].coins[coinId].transactions.hasOwnProperty(txId);
};
