import React, { useState } from 'react'
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getCoinsOfPortfolio } from '../../core/app/selectors';
import './Chart.scss';

const BTCChart = (props) => {

  const [ days, setDays ] = useState(90);
 
  const pfCoins = props.coinsForChart(props.pfId);

  const getSeries = () => {
    let series = [];
    let baseSymbolData = [];
  
    for (const coin of Object.values(pfCoins)) {
      // only if we have pricedata for this coin
      if (typeof props.prices[coin.symbol] === "object") {
        const coinDays = Object.keys(props.prices[coin.symbol]).length;
        if (coinDays > 0) {
          let data = [];
          const start = coinDays - days;
          const first = props.prices[coin.symbol][start].close;
          for (let i = start; i < coinDays; i++) {
            const price = props.prices[coin.symbol][i];
            const timestamp = price.time * 1000;
            const value = price.close * 100 / first - 100;
            data.push({
              x: timestamp,
              y: value
            });
            // build data for base currency (only in first cycle)
            if (!series.length) {
              baseSymbolData.push({
                x: timestamp,
                y: 0
              });
            }
          }
    
          // add 0% straight line for base currency (only in first cycle)
          if (!series.length) {
            series.push({
              name: 'BTC',
              type: 'line',
              data: baseSymbolData,
              visible: false,
              zIndex: 1
            });
          }
          series.push({
            name: coin.symbol,
            type: 'line',
            data: data,
            zIndex: 1
          });
        }
      }
    }
  
    return series;
  };
  
  const getConfig = () => {
    return {
      chart: {
        height: 600,
        zoomType: 'x'
      },
      plotOptions: {
        series: {
          // disable limit of 1000 data objects in points
          turboThreshold: 0
        }
      },
      title: null,
      tooltip: {
        //pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f} %</b>'
        valueDecimals: 2,
        valueSuffix: ' %'
      },
      xAxis: {
        title: {
          text: false
        },
        type: 'datetime'
      },
      yAxis: {
        labels: {
          format: "{value:.0f} %"
        },
        title: {
          text: 'Price diff to BTC'
        }
      },
      series: getSeries(),
    }
  };
  
  const getButtons = () => {
    const timespans = [
      {
        d: 90,
        l: '3M'
      },
      {
        d: 60,
        l: '2M'
      },
      {
        d: 30,
        l: '1M'
      },
      {
        d: 14,
        l: '2W'
      },
      {
        d: 7,
        l: '1W'
      },
    ];
    let buttons = [];
    for (let ts of timespans) {
      const btnClass = 'btn btn-' + (days === ts.d ? 'secondary' : 'outline-secondary');
      buttons.push(
        <button key={ ts.d } onClick={ (e) => setDays(ts.d) } className={ btnClass }>
          { ts.l }
        </button>
      );
    }
    return buttons;
  }

  if (pfCoins && props.prices) {
    return (
      <div className="crypto-chart">
        <h2 className="my-4">Price diff to BTC</h2>
        <div className="tabs">
          { getButtons() }
        </div>
        <HighchartsReact
          highcharts={ Highcharts }
          options={ getConfig() }
        />
      </div>
    );
  } else {
    return (
      <div className="loading">
        Loading data for chart…
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    coinsForChart: (pfId) => getCoinsOfPortfolio(pfId)(state),
    prices: state.app.histories.BTC,
  }
}

export default connect(mapStateToProps)(BTCChart);