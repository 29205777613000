export const COLOR_RGBA_GREEN = 'rgba(94, 180, 0, 0.35)';
export const COLOR_RGBA_RED = 'rgba(208, 2, 27, 0.35)';
export const DEFAULT_CURRENCY = 'EUR';
export const DEFAULT_EU_DECIMALS = true;
export const CHART_HEIGHT = 600;
export const MAX_DECIMALS = 18;

export const FIREBASE_DB_URL = "https://crypto-d-default-rtdb.europe-west1.firebasedatabase.app";

export default {
  DEFAULT_CURRENCY: DEFAULT_CURRENCY,
  DEFAULT_EU_DECIMALS: DEFAULT_EU_DECIMALS,
  CONVERSION_CURRENCY: 'USD',
  DECIMALS_PRICE: 2,
  DECIMALS_PERCENT: 4,
  DECIMALS_PIECES: 8,
  MAX_DECIMALS: MAX_DECIMALS,
  RGBA_GREEN: COLOR_RGBA_GREEN,
  RGBA_RED: COLOR_RGBA_RED,
  CHART_HEIGHT: CHART_HEIGHT,
};
